import $ from 'jquery';
import React from 'react';
import Cart_Canvas from '../sample_comp/cart_canvas';
import Comp_Popup_Contact from '../component_version2/comp_popup_contact';
import './footer.css';
let cartItems;
class Footer extends React.Component{
    componentDidMount(){
        cartItems=localStorage.getItem("_cart");
        
        var strList="";
          let finalAmt=0;
        if(cartItems!=null && cartItems!==""){
          cartItems=JSON.parse(cartItems);
          //alert("i am in cart item "+cartItems.length);
          $("._cart_elm_count").html(cartItems.length);
          $("._cart_elm_sun").html("₹"+window.cAmount); 
        }
      }
    render(){
        return(
        <div>
            <Comp_Popup_Contact/>
        <div className="footer-area">
        <div className="footer-container">
            <div className="footer-top">
                <div className="container">
                    <div className="row">
                        
                        <div className="col-md-6 col-lg-4 mb-md-30px mb-lm-30px">
                            <div className="single-wedge">
                                <h4 className="footer-herading">Astro Mansha</h4>
                                {/* <h4 className="footer-herading">Fast RC & Hobbies LLC</h4> */}
                                <p className="about-text">
                                I trust that there is nothing more powerful than God and hence a small spiritual remedy can bring a big beautiful positive change in lives.
                                </p>
                                <ul className="link-follow">
                               
                                    <li className="li">
                                        <a className="icon-social-facebook" target={"blank"} title="Facebook" href="https://www.facebook.com/mansha.mishra"></a>
                                    </li>
                                    <li className="li">
                                        <a className="ion-social-linkedin-outline" target={"blank"} title="Linkedin" href="https://www.linkedin.com/in/mansha-mishra-3954a2281/"></a>
                                    </li>
                                    <li className="li">
                                        <a className="icon-social-youtube" target={"blank"} title="Youtube" target="blank" href="https://www.youtube.com/@AstroMansha"></a>
                                    </li>
                                    
                                    <li className="li">
                                        <a className="icon-social-instagram" target={"blank"} target="blank" title="Instagram" href="https://www.instagram.com/manshamishraa/"></a>
                                    </li>
                                    {/* <li className="li">
                                        <a className="linkedin icon-social-linkedin" title="Linkedin" href="#"></a>
                                    </li> */}
                                </ul>
                            </div>
                        </div>
                       
                        <div className="col-md-6 col-sm-6 pl-10 col-lg-3 mb-md-30px mb-lm-30px">
                            <div className="single-wedge">
                                <h4 className="footer-herading">information</h4>
                                <div className="footer-links">
                                    <div className="footer-row">
                                        <ul className="align-items-center">
                                            <li className="li"><a className="single-link" href="../aboutme">About Me</a></li>
                                            <li className="li"><a className="single-link" href="../consultation">Consultation</a></li>
                                            <li className="li"><a className="single-link" href="../terms_condition">Terms & Condition</a></li>
                                            <li className="li"><a className="single-link" href="../privacy_policy">Privacy & Policy</a></li>
                                            {/* <li className="li"><a className="single-link" href="../shipping_policy">Delivery & Shipping Policy</a></li>
                                            <li className="li"><a className="single-link" href="../return_refund_cancellation_policy">Return & Refund Policy</a></li> */}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="col-md-6 col-lg-2 col-sm-6 mb-lm-30px">
                            <div className="single-wedge">
                                <h4 className="footer-herading">Quick Links</h4>
                                <div className="footer-links">
                                    <div className="footer-row">
                                        <ul className="align-items-center">
                                            <li className="li"><a className="single-link" href="../contactus">Contact Us
                    </a>
                                            </li>
                                            <li className="li"><a className="single-link" href="../astrology">Astrology</a></li>
                                            <li className="li"><a className="single-link" href="../spirituality_and_mysticism">Spirituality & Mysticism</a></li>
                                            {/* <li className="li"><a className="single-link" href="../specialties">Our Specialities</a></li> */}
                                            {/* <li className="li"><a className="single-link" href="../treatments">Treatments</a></li> */}
                                            {/* <li className="li"><a className="single-link" href="../contactus">Contact Us</a></li> */}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                       
                        <div className="col-md-6 col-lg-3">
                            <div className="single-wedge">
                                <h4 className="footer-herading">newsletter</h4>
                                <div className="footer-links">
                                    
                                    <div id="mc_embed_signup" className="subscribe-form">
                                        <form id="mc-embedded-subscribe-form" className="validate" novalidate="" target="_blank" name="mc-embedded-subscribe-form" method="post" action="http://devitems.us11.list-manage.com/subscribe/post?u=6bbb9b6f5827bd842d9640c82&amp;id=05d85f18ef">
                                            <div id="mc_embed_signup_scroll" className="mc-form">
                                                <input className="email" type="email" required="" placeholder="Your Mail*" name="EMAIL" value="" />
                                                <div className="mc-news" aria-hidden="true">
                                                    <input type="text" value="" tabindex="-1" name="b_6bbb9b6f5827bd842d9640c82_05d85f18ef" />
                                                </div>
                                                <div className="clear">
                                                    <button id="mc-embedded-subscribe" className="button btn-primary" type="submit" name="subscribe" value=""><i
                                                            className="icon-cursor"></i> Subscribe Now</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
            <div className="footer-bottom">
                <div className="container">
                    <div className="row flex-sm-row-reverse">
                        {/* <div className="col-md-6 text-right">
                            <div className="payment-link">
                                <img src="assets/images/icons/payment.png" alt="" />
                            </div>
                        </div> */}
                        <div className="col-md-12 text-left">
                            <p className="copy-text">© Copyright 2024 <strong>MysticMansha</strong> <a className="company-name" href="javascript:void(0)">
                                {/* <strong>ashtechnologies.in</strong> */}
                                </a>.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
        <Cart_Canvas/>
        <div className="offcanvas-overlay"></div>
        </div>);
    }
}
export default Footer;