import React from 'react';
import {appGetUrlPath} from '../function_lib/common_lib';
import './product_slider.css';
export function Product_Slider(lsData,isCategoryId="0",isTitle="Featured Products",sIndex=0,isNewTab=false,viewUri="javascript:void(0)"){
   // alert(lsData);
    var appUrtPath=appGetUrlPath();
var listData=JSON.parse(lsData);
//alert(listData.length);
if(listData.length===0){
    return "";
}
var strResp="";
let offerPer=0;
let offerMsg="";
let oldPrice="";
let youSaved="";

let _targetBlank="";
let repeateProducts=1;
if(isNewTab){
    _targetBlank="target=\"_blank\"";
}

var strHeader="<div class=\"section\ mt-1\">"+
"<div class=\"container g-box-shadow1\">"+
  
        "<div class=\"heading page__title-bar\">"+
        "<h3 class=\"title\">"+isTitle+"</h3>"+
        "<div class=\"page__title-right\">"+
         "<span class=\"badges hover-image\"><span class=\"new\"><a "+_targetBlank+" href=\""+viewUri+"\">View All</a></span></span>"+
        "</div>"+
    "</div>"+
    "<div class=\"new-product-slider"+sIndex+" swiper-container swiper slider-nav-style-1\"  sliderPerView=\"5\" >"+
        "<div class=\"new-product-wrapper f-wrapper swiper-wrapper \">";

var strFooter="</div>"+"<div class=\"swiper-buttons\">"+
"<div class=\"swiper-button-prev  swiper-button-prev"+sIndex+"\" tabindex=\"-1\" role=\"button\" aria-label=\"Previous slide\" aria-disabled=\"true\"></div>"+
"<div class=\"swiper-button-next  swiper-button-next"+sIndex+"\" tabindex=\"0\" role=\"button\" aria-label=\"Next slide\" aria-disabled=\"false\"></div>"+
"</div>"+
"</div></div></div>";
for(var i=0;i<listData.length;i++){
    
    offerMsg="";
    oldPrice=""; 
    youSaved=""; 
    var imgPath=["default-product.png"];  
   
    offerPer=parseInt(listData[i]["offer_per"]);
    let imgNm=listData[i]["img_path"].split(',');
    if(listData[i]["pre_cost"]!==listData[i]["cost"]){
        oldPrice="<span class=\"old\">₹"+listData[i]["pre_cost"]+"</span>";
        youSaved="<span class=\"small\" style=\"color:#ee7600\">"+"save ₹ "+parseFloat(parseFloat(listData[i]["pre_cost"])-parseFloat(listData[i]["cost"])).toFixed(2)+"/-";
        if(offerPer!==0){
            youSaved+="</span>";
            //youSaved+="("+listData[i]["offer_per"]+"% off)</span>";
        }else{
            youSaved+="</span>";
        }
    }
  
    if(offerPer!==0){
       offerMsg="<span class=\"new\">"+offerPer+"% off</span>";
    }
    let addToCartButton="<button type=\"button\" title=\"Add To Cart\" class=\"_addToCart ext_addto \">Add To Cart</button>";
    if(parseFloat(listData[i]["max_limit_qty"])<=0){
       offerMsg="<span class=\"out\">"+"Out of Stock"+"</span>";
       addToCartButton="<button type=\"button\" title=\"Not in Stock\" class=\"_addToCart111 ext_addto \">Not in Stock</button>";
    }
    
    let proCName=listData[i]["product_name"].replace(/\s/g, '_');
    proCName=proCName.replace(/\//g,'-');
    
    //let proCName=listData[i]["product_name"];
    //proCName=proCName.replace(/\//g,'-');

   let proUrl= encodeURI("../product/"+proCName+"?rf="+listData[i]["rf_id"]);
   if(typeof listData[i]["can_uri"]!=="undefined"){
    if(listData[i]["can_uri"]!==""){
        proUrl=encodeURI("../product/"+listData[i]["can_uri"]);
    }
 }
   let skuSec="";
  /* let skuSec="<div class=\"d-flex justify-content-between mb-3\">"+
   "<h5 class=\"mb-0 small\">"+listData[i]["product_code"]+"</h5>"+
   "<div class=\"ps-2 small\">"+
   "<small class=\"fa fa-star text-primary\"></small>"+
   "<small class=\"fa fa-star text-primary\"></small>"+
   "<small class=\"fa fa-star text-primary\"></small>"+
   "<small class=\"fa fa-star text-primary\"></small>"+
   "<small class=\"fa fa-star text-primary\"></small>"+
   "</div>"+
   "</div>";
   */
/*
   let fetchOption="<div class=\"d-flex mb-3\">"+
   "<small class=\"border-end me-3 pe-3\"><i class=\"fa fa-bed text-primary me-2\"></i>3 Bed</small>"+
   "<small class=\"border-end me-3 pe-3\"><i class=\"fa fa-bath text-primary me-2\"></i>2 Bath</small>"+
   "<small><i class=\"fa fa-wifi text-primary me-2\"></i>Wifi</small>"+
   "</div>";
  */ 
    if(isCategoryId==="0"){
        strResp+="<div class=\"new-product-item product f-wrapper-col swiper-slide g-box-shadow m-2\">"+
        "<div class=\"product f-col-item\">"+
        "<form>"+
        "<input type=\"hidden\" name=\"item_name\" value=\""+listData[i]["product_name"]+"\" />"+
        "<input type=\"hidden\" name=\"item_code\" value=\""+listData[i]["product_code"]+"\"/>"+
        "<input type=\"hidden\" name=\"item_rate\" value=\""+listData[i]["cost"]+"\" />"+
        "<input type=\"hidden\" name=\"pre_cost\" value=\""+listData[i]["pre_cost"]+"\" />"+
        "<input type=\"hidden\" name=\"offer_per\" value=\""+listData[i]["offer_per"]+"\" />"+
        "<input type=\"hidden\" name=\"comp_name\" value=\""+"0"+"\" />"+
        "<input type=\"hidden\" name=\"item_qty\" value=\""+"1"+"\" />"+
        "<input type=\"hidden\" name=\"enc_tmp_id\" value=\""+listData[i]["rf_id"]+"\" />"+
    
        "<input type=\"hidden\" name=\"imgs\" value=\""+imgNm[0]+"\" />"+
        "<input type=\"hidden\" name=\"item_rf_id\" value=\""+listData[i]["id"]+"\" />"+
            "<div class=\"thumb pro-img-div\">"+
                "<a "+_targetBlank+" href=\""+proUrl+"\" class=\"image\">"+
                    "<img src=\""+appUrtPath+imgNm[0]+"\" class=\"w-100\" alt=\"Product\" />"+
                    "<img class=\"hover-image\" src=\""+appUrtPath+imgNm[0]+"\" alt=\"Product\" />"+
                "</a>"+
                "<span class=\"badges\">"+
                offerMsg+
                    // "<span class=\"new\">"+offerMsg+"</span>"+
                "</span>"+
                "<div class=\"actions\">"+
                    // "<a href=\"#\" class=\"action quickview\" data-link-action=\"quickview\" title=\"Quick view\" data-bs-toggle=\"modal\" data-bs-target=\"#exampleModal\"><i class=\"icon-size-fullscreen\"></i></a>"+
                "</div>"+
                //"<button type=\"button\" title=\"Add To Cart\" class=\"_addToCart add-to-cart\">Add To Cart</button>"+
            "</div>"+
            
            "<div class=\"content content-h\">"+
            skuSec+
            
                "<h6 class=\"title\"><a "+_targetBlank+" href=\""+proUrl+"\">"+listData[i]["product_name"]+"</a></h6>"+
                "<span class=\"price title\">"+
                "<span class=\"new\">₹"+listData[i]["cost"]+"</span>"+
                oldPrice+
                "</span>"+
                "<span class=\"price myp\">"+youSaved+"</span>"+
                addToCartButton+
                //"<button type=\"button\" title=\"Add To Cart\" class=\"_addToCart ext_addto \">Add To Cart</button>"+
            "</div>"+
        "</form>"+"</div>"+
    "</div>";
    }else if(isCategoryId===listData[i]["category_id"]){
        strResp+="<div class=\"new-product-item product f-wrapper-col swiper-slide g-box-shadow m-2\">"+
        "<div class=\"product f-col-item\">"+
        "<form>"+
        "<input type=\"hidden\" name=\"item_name\" value=\""+listData[i]["product_name"]+"\" />"+
        "<input type=\"hidden\" name=\"item_code\" value=\""+listData[i]["product_code"]+"\"/>"+
        "<input type=\"hidden\" name=\"item_rate\" value=\""+listData[i]["cost"]+"\" />"+
        "<input type=\"hidden\" name=\"pre_cost\" value=\""+listData[i]["pre_cost"]+"\" />"+
        "<input type=\"hidden\" name=\"offer_per\" value=\""+listData[i]["offer_per"]+"\" />"+
        "<input type=\"hidden\" name=\"comp_name\" value=\""+"0"+"\" />"+
        "<input type=\"hidden\" name=\"item_qty\" value=\""+"1"+"\" />"+
        "<input type=\"hidden\" name=\"enc_tmp_id\" value=\""+listData[i]["rf_id"]+"\" />"+
    
        "<input type=\"hidden\" name=\"imgs\" value=\""+imgNm[0]+"\" />"+
        "<input type=\"hidden\" name=\"item_rf_id\" value=\""+listData[i]["id"]+"\" />"+
            "<div class=\"thumb pro-img-div\">"+
                "<a "+_targetBlank+" href=\""+proUrl+"\" class=\"image\">"+
                    "<img src=\""+appUrtPath+imgNm[0]+"\" class=\"w-100\" alt=\"Product\" />"+
                    "<img class=\"hover-image\" src=\""+appUrtPath+imgNm[0]+"\" alt=\"Product\" />"+
                "</a>"+
                "<span class=\"badges\">"+
                offerMsg+
                    //"<span class=\"new\">"+offerMsg+"</span>"+
                "</span>"+
                "<div class=\"actions\">"+
                    // "<a href=\"#\" class=\"action quickview\" data-link-action=\"quickview\" title=\"Quick view\" data-bs-toggle=\"modal\" data-bs-target=\"#exampleModal\"><i class=\"icon-size-fullscreen\"></i></a>"+
                "</div>"+
                //"<button type=\"button\" title=\"Add To Cart\" class=\"_addToCart add-to-cart\">Add To Cart</button>"+
            "</div>"+
            
            "<div class=\"content content-h\">"+
            skuSec+
            
                "<h6 class=\"title\"><a "+_targetBlank+" href=\""+proUrl+"\">"+listData[i]["product_name"]+"</a></h6>"+
                "<span class=\"price title\">"+
                "<span class=\"new\">₹"+listData[i]["cost"]+"</span>"+
                oldPrice+
                "</span>"+
                "<span class=\"price myp\">"+youSaved+"</span>"+
                addToCartButton+
                //"<button type=\"button\" title=\"Add To Cart\" class=\"_addToCart ext_addto \">Add To Cart</button>"+
            "</div>"+
        "</form>"+"</div>"+
    "</div>";
    }
   
    if(listData.length<4){
        if(i===listData.length-1 && repeateProducts===1){
            i=-1;
            repeateProducts=2;
            //alert("i m in condition");
        }
    }
}


return strHeader+strResp+strFooter;
}
export default Product_Slider;